import React from 'react'
import Layout from '../../components/Layout'
import {portfolio} from '../../styles/portfolio.module.css'
export default function Portfolio() {
    return (
        <Layout pageTitle="Portfolio">
            <div className={portfolio}>
                <h2>Music Portfolio</h2>
                <h3>Music we have produced</h3>
            </div>
        </Layout>
    )
}